// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-post-tsx": () => import("./../../../src/pages/blogPost.tsx" /* webpackChunkName: "component---src-pages-blog-post-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-page-tsx": () => import("./../../../src/pages/privacyPolicyPage.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-page-tsx" */),
  "component---src-pages-sections-about-me-about-me-tsx": () => import("./../../../src/pages/sections/about-me/AboutMe.tsx" /* webpackChunkName: "component---src-pages-sections-about-me-about-me-tsx" */),
  "component---src-pages-sections-about-me-index-ts": () => import("./../../../src/pages/sections/about-me/index.ts" /* webpackChunkName: "component---src-pages-sections-about-me-index-ts" */),
  "component---src-pages-sections-footer-footer-tsx": () => import("./../../../src/pages/sections/footer/Footer.tsx" /* webpackChunkName: "component---src-pages-sections-footer-footer-tsx" */),
  "component---src-pages-sections-footer-index-ts": () => import("./../../../src/pages/sections/footer/index.ts" /* webpackChunkName: "component---src-pages-sections-footer-index-ts" */),
  "component---src-pages-sections-scan-to-bim-index-ts": () => import("./../../../src/pages/sections/scan-to-BIM/index.ts" /* webpackChunkName: "component---src-pages-sections-scan-to-bim-index-ts" */),
  "component---src-pages-sections-scan-to-bim-scan-to-bim-tsx": () => import("./../../../src/pages/sections/scan-to-BIM/ScanToBim.tsx" /* webpackChunkName: "component---src-pages-sections-scan-to-bim-scan-to-bim-tsx" */),
  "component---src-pages-sections-tutorials-index-ts": () => import("./../../../src/pages/sections/tutorials/index.ts" /* webpackChunkName: "component---src-pages-sections-tutorials-index-ts" */),
  "component---src-pages-sections-tutorials-tutorials-tsx": () => import("./../../../src/pages/sections/tutorials/Tutorials.tsx" /* webpackChunkName: "component---src-pages-sections-tutorials-tutorials-tsx" */)
}

